// config.js

const getEnvironment = () => {
  const hostname = window.location.hostname;
  if (hostname === "proprompt.store") {
    return "production";
  } else {
    return "development";
  }
};

const config = {
  development: {
    apiBaseURL: "https://proprompt-store-api.codedesign.ai",
    env: "development",
  },
  production: {
    apiBaseURL: "https://proprompt-store-api.codedesign.ai",
    env: "production",
  },
};

// Function to return the appropriate API endpoint based on the environment
const returnAPI = () => {
  const env = getEnvironment();
  return config[env].apiBaseURL;
};

export { returnAPI, getEnvironment };
