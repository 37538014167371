import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import DropdownMenu from "./DropdownMenu";
import "./PromptList.css";
import { getTextColor } from "./lib";
import { IoIosFolder, IoIosFolderOpen } from "react-icons/io";
import { IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { makeStyles } from "@mui/styles";
import ReactMarkdown from "react-markdown";

import {
  MdOutlineFolderCopy,
  MdOutlineDriveFolderUpload,
} from "react-icons/md";
import {
  GrActions,
  GrEmoji,
  GrFan,
  GrProjects,
  GrTarget,
  GrTest,
  GrPieChart,
} from "react-icons/gr";
import { GiAnchor } from "react-icons/gi";
import { DataContext } from "./DataContext";
import {
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
  FormControl,
  Paper,
} from "@mui/material";

import { styled } from "@mui/system";

const iconMap = {
  IoIosFolder: IoIosFolder,
  IoIosFolderOpen: IoIosFolderOpen,
  MdOutlineFolderCopy: MdOutlineFolderCopy,
  MdOutlineDriveFolderUpload: MdOutlineDriveFolderUpload,
  GrActions: GrActions,
  GrEmoji: GrEmoji,
  GrFan: GrFan,
  GrProjects: GrProjects,
  GrTarget: GrTarget,
  GrTest: GrTest,
  GrPieChart: GrPieChart,
  GiAnchor: GiAnchor,
};

const StyledPaper = styled(Paper)({
  maxHeight: 300, // Set max height for the dropdown
});

const useStyles = makeStyles(() => ({
  customTooltip: {
    background: (props) => props.color,
    color: "#0a0a0a", // Ensure text is black
    fontSize: "13px",
    lineHeight: "1.5",
    padding: "10px",
    fontWeight: "400",
  },
}));

const Item = ({ item, type, onEdit, onClick, provided, notes, searchTerm }) => {
  const navigate = useNavigate();
  const {
    handleDelete,
    data,
    handleUpdate,
    handleDuplicate,
    origin,
    handleExport,
  } = useContext(DataContext);
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(null);

  const handleClick = (e) => {
    console.log(e.currentTarget, "testtinggg");
    if (type === "collection") {
      navigate(`/collections/${item.id}`);
    } else {
      if (searchTerm && searchTerm.length > 3) {
        navigate(`/collections/${item.collectionId}`);
      }
      onClick(item);
    }
  };

  const hexToRgba = (hex, alpha) => {
    if (!hex) return `rgba(0,0,0,1)`;
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    const darken = (color) => Math.floor(color * alpha);

    return `rgba(${darken(r)}, ${darken(g)}, ${darken(b)}, 1)`;
  };

  const renderIcon = (iconName) => {
    if (iconName?.startsWith("https://")) {
      return (
        <div className="icon-container">
          <img
            src={iconName}
            alt="icon"
            style={{ width: "35px", height: "30px", objectFit: "contain" }}
          />
        </div>
      );
    }

    const IconComponent = iconMap[iconName];
    return (
      <div className="icon-container">
        {IconComponent ? <IconComponent size={20} /> : <span>{iconName}</span>}
      </div>
    );
  };

  const textColor = getTextColor(item.color);
  console.log(textColor, item.color, "testing01");

  console.log(textColor);

  const handleMove = () => {
    setMoveDialogOpen(true);
  };

  const handleMoveConfirm = () => {
    if (type === "prompt")
      handleUpdate({ ...item, collectionId: selectedCollection.id });
    else handleUpdate({ ...item, parentId: selectedCollection.id });

    setTimeout(() => {
      setMoveDialogOpen(false);
    }, 500);
  };

  const handleCollectionChange = (event, newValue) => {
    setSelectedCollection(newValue);
  };
  function generateGradient(color) {
    function lightenColor(color, percent) {
      const num = parseInt(color?.slice(1), 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        G = ((num >> 8) & 0x00ff) + amt,
        B = (num & 0x0000ff) + amt;

      return `#${(
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
        (B < 255 ? (B < 1 ? 0 : B) : 255)
      )
        .toString(16)
        .slice(1)
        .toUpperCase()}`;
    }

    const subtleLightColorStart = lightenColor(color, 65); // Lighten start color by 65%
    const subtleLightColorEnd = lightenColor(color, 30); // Lighten end color by 30%

    return `linear-gradient(135deg, ${subtleLightColorStart}, ${subtleLightColorEnd})`;
  }

  const tooltipBackground = generateGradient(item.color);

  const classes = useStyles({ color: tooltipBackground });

  return (
    <>
      {type === "prompt" && (
        <Tooltip
          classes={{ tooltip: classes.customTooltip }}
          title={<ReactMarkdown>{item.notes}</ReactMarkdown>} // Render markdown
          disableHoverListener={!item.notes}
          placement="right"
        >
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={`${type}-item`}
            style={{
              backgroundColor: item.color,
              ...provided.draggableProps.style,
            }}
            onClick={handleClick}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = hexToRgba(
                item.color,
                0.9
              ))
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = item.color)
            }
          >
            <div
              className={`${type}-content`}
              {...provided.dragHandleProps}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span className="drag-handle" style={{ marginRight: 10 }}>
                &#x2630;
              </span>
              <span
                className="icon"
                style={{ marginRight: 10, marginTop: "4px" }}
              >
                {renderIcon(item.icon)}
              </span>
              <div
                className="name-and-path"
                style={{ flex: 1, display: "flex", flexDirection: "column" }}
              >
                <span
                  className="name"
                  style={{
                    marginBottom: 1,
                    textAlign: "left",
                    color: textColor,
                  }}
                >
                  {item.name}
                </span>

                {/* {item.notes && (
                <span className="notes" style={{ fontSize: "0.8rem" }}>
                  {item.notes && item.notes}
                </span>
              )} */}
                {type === "prompt" && <span className="path">{item.path}</span>}
              </div>

              {origin === "master" && !searchTerm && (
                <IconButton
                  onClick={(e) => {
                    handleExport(item.id);
                    e.stopPropagation();
                  }}
                  aria-label="download"
                  size="small"
                  style={{
                    marginRight: 30,
                    marginTop: 4,
                    pointerEvents: "all",
                  }}
                >
                  <DownloadIcon />
                </IconButton>
              )}

              {origin === "master" && (
                <DropdownMenu
                  onEdit={() => onEdit(item)}
                  onDelete={() => handleDelete(item)}
                  onMove={handleMove}
                  onDuplicate={() => handleDuplicate(item)}
                  type={"prompt"}
                  searchTerm={searchTerm}
                />
              )}
            </div>
          </div>
        </Tooltip>
      )}
      {type === "collection" && (
        <Tooltip
          classes={{ tooltip: classes.customTooltip }}
          title={<p>{`Notes: ${item.notes}`}</p>}
          disableHoverListener={!item.notes}
          placement="right"
        >
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={`${type}-item`}
            style={{
              backgroundColor: item.color,
              ...provided.draggableProps.style,
            }}
            onClick={handleClick}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = hexToRgba(
                item.color,
                0.9
              ))
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = item.color)
            }
          >
            <div
              className={`${type}-content`}
              {...provided.dragHandleProps}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="drag-handle" style={{ marginRight: 10 }}>
                &#x2630;
              </span>
              <span
                className="icon"
                style={{ marginRight: 10, marginTop: "4px" }}
              >
                {renderIcon(item.icon)}
              </span>
              <div
                className="name-and-path"
                style={{ flex: 1, display: "flex", flexDirection: "column" }}
              >
                <span
                  className="name"
                  style={{ marginBottom: 5, color: textColor }}
                >
                  {item.name}
                </span>
              </div>

              {origin === "master" && (
                <IconButton
                  onClick={(e) => {
                    handleExport(item.id);
                    e.stopPropagation();
                  }}
                  aria-label="download"
                  size="small"
                  style={{
                    marginRight: 30,
                    marginTop: 4,
                    pointerEvents: "all",
                  }}
                >
                  <DownloadIcon />
                </IconButton>
              )}
              {origin === "master" && (
                <DropdownMenu
                  itemId={item.id}
                  onEdit={() => onEdit(item)}
                  onDelete={() => {
                    console.log(item, "to be deleted");
                    handleDelete(item);
                  }}
                  onMove={handleMove}
                  onDuplicate={() => handleDuplicate(item)}
                  type={"collection"}
                />
              )}
            </div>
          </div>
        </Tooltip>
      )}
      <Dialog
        open={moveDialogOpen}
        onClose={() => setMoveDialogOpen(false)}
        maxWidth="md"
        fullWidth
        sx={{ height: "80vh" }}
      >
        <DialogTitle>Move Item</DialogTitle>
        <br />
        <DialogContent sx={{ overflow: "hidden" }}>
          <FormControl fullWidth>
            <Autocomplete
              options={[
                ...data.collections,
                { name: "Front Collection", id: null },
              ]}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Select Collection" />
              )}
              PaperComponent={StyledPaper}
              value={selectedCollection}
              onChange={handleCollectionChange}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMoveDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleMoveConfirm} color="primary">
            Move
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Item;
