import React, { useState, useContext, useEffect, useMemo } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import "./Main.css";
import SearchBar from "./SearchBar";
import TabNavigation from "./TabNavigation";
import AddPrompt from "./AddPrompt";
import AddFolder from "./AddFolder";
import { DataContext } from "./DataContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Item from "./Item";
import PromptPopup from "./PromptPopup";
import "./PromptList.css";
import { ReactComponent as BackButton } from "./svg/BackButton.svg";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import EditPrompt from "./EditPrompt";
import EditFolder from "./EditFolder";
import { CircularProgress } from "@mui/material";
import { UserContext } from "./UserContext";
import Fuse from "fuse.js";

const Main = () => {
  const { user } = useContext(UserContext); // Access loading state

  const [searchTerm, setSearchTerm] = useState("");
  const [promptPopupStatus, setPromptPopupStatus] = useState(null);
  const [folderPopupStatus, setFolderPopupStatus] = useState(null);

  const [itemPopupContent, setItemPopupContent] = useState(null);

  const [loading, setLoading] = useState(true);
  const { parentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    data: { collections, prompts, allCollections, allPrompts },
    fetchingAdditionalPrompts,
    origin,
    handleUpdateOrder,
  } = useContext(DataContext);
  console.log(fetchingAdditionalPrompts, "testingjoe1");
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (
      (collections?.length > 0 || prompts?.length > 0) &&
      !fetchingAdditionalPrompts
    ) {
      setLoading(false); // Set loading to false when data is loaded
    }
  }, [collections, prompts]);
  // Optimized filterCollections function with fuzzy search
  const filterCollections = (collections, term, allCollections, parentId) => {
    console.log(collections, allCollections, term, "testingjoe3");

    if (!term || !allCollections) {
      return collections;
    }

    const fuseOptions = {
      keys: ["name", "notes"], // Fuzzy match on the 'name' field
      threshold: 0.4, // Adjust to make the search more lenient
      tokenize: true, // Enable tokenization
      matchAllTokens: true, // Ensure all tokens (words) must match
    };

    const fuse = new Fuse(allCollections, fuseOptions);

    const getChildCollections = (parentId = null) => {
      const children = allCollections.filter((c) => c.parentId === parentId);
      return children.concat(
        children.flatMap((child) => getChildCollections(child.id))
      );
    };

    const childCollections = getChildCollections(parentId);

    const filteredResults = fuse.search(term).map((result) => result.item);

    return childCollections.filter((collection) =>
      filteredResults.some((result) => result.id === collection.id)
    );
  };

  const filterPrompts = (prompts, term, allPrompts, collectionId = null) => {
    console.log(prompts, allPrompts, term, "testingjoe4");

    if (!term || !allPrompts) {
      return prompts;
    }

    const fuseOptions = {
      keys: ["name", "notes", "prompt"], // Fuzzy match on the 'name' field
      threshold: 0.4, // Adjust to make the search more lenient
      tokenize: true, // Enable tokenization
      matchAllTokens: true, // Ensure all tokens (words) must match
    };

    const fuse = new Fuse(allPrompts, fuseOptions);

    const getChildPrompts = (collectionId) => {
      const directPrompts = allPrompts.filter(
        (p) => p.collectionId === collectionId
      );
      const childCollections = allCollections.filter(
        (c) => c.parentId === collectionId
      );
      return directPrompts.concat(
        childCollections.flatMap((child) => getChildPrompts(child.id))
      );
    };

    const childPrompts = getChildPrompts(collectionId);

    const filteredResults = fuse.search(term).map((result) => result.item);

    return childPrompts.filter((prompt) =>
      filteredResults.some((result) => result.id === prompt.id)
    );
  };
  const getCollectionsByParentId = (collections = [], parentId) => {
    return collections.filter((collection) => collection.parentId === parentId);
  };

  const getPromptsByCollectionId = (prompts = [], collectionId) => {
    return prompts.filter((prompt) => prompt.collectionId === collectionId);
  };

  let currentCollection = null;
  let collectionsToRender = [];
  let promptsToRender = [];

  console.log(location.pathname, window.location.pathname, "testingjoet");
  if (location.pathname === "/collections" || location.pathname === "/") {
    const isPrompts =
      window.location.pathname === "/prompts/" ||
      window.location.pathname === "/prompts";
    if (isPrompts) {
      collectionsToRender = getCollectionsByParentId(
        collections,
        "Lx2VSv07LplHyGPpI7tt"
      );
      promptsToRender = getPromptsByCollectionId(
        prompts,
        "Lx2VSv07LplHyGPpI7tt"
      );
    } else {
      collectionsToRender = getCollectionsByParentId(collections, null);
      promptsToRender = getPromptsByCollectionId(prompts, null);
    }
  } else if (parentId) {
    currentCollection = collections?.find(
      (collection) => collection.id === parentId
    );
    collectionsToRender = getCollectionsByParentId(collections, parentId);
    promptsToRender = getPromptsByCollectionId(prompts, parentId);
  }

  const filteredCollections = useMemo(() => {
    return filterCollections(
      collectionsToRender,
      searchTerm,
      allCollections,
      parentId
    );
  }, [collectionsToRender, searchTerm, allCollections, parentId]);

  const filteredPrompts = useMemo(() => {
    return filterPrompts(promptsToRender, searchTerm, allPrompts, parentId);
  }, [promptsToRender, searchTerm, allPrompts, parentId]);

  const handleCopyPrompt = (promptText) => {
    if (!promptText) return;
    // Remove HTML tags and replace them with newlines
    const plainText = promptText.replace(/<\/?[^>]+(>|$)/g, "\n");

    navigator.clipboard.writeText(plainText).then(
      () => {
        MySwal.fire({
          toast: true,
          position: "bottom-left",
          icon: "success",
          title: "Copied to clipboard",
          showConfirmButton: false,
          timer: 3000,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  // const hexToRgba = (hex, alpha) => {
  //   const r = parseInt(hex.slice(1, 3), 16);
  //   const g = parseInt(hex.slice(3, 5), 16);
  //   const b = parseInt(hex.slice(5, 7), 16);
  //   return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  // };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    let items = [...filteredCollections];
    let type = "collections";

    if (source.droppableId === "prompts") {
      items = [...filteredPrompts];
      type = "prompts";
    }

    console.log(
      items,
      source,
      "testingchild",
      filteredCollections,
      filteredPrompts
    );
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);

    if (type === "collections") {
      // setFilteredCollections(items);
    } else {
      // setFilteredPrompts(items);
    }

    const newOrder = items.map((item) => item.id);

    console.log(currentCollection, "testingmin");
    handleUpdateOrder(currentCollection?.id, newOrder, type);
  };

  const handleEdit = (item) => {
    if (item.hasOwnProperty("collectionId")) {
      // Implement edit functionality
      setPromptPopupStatus(true);
      setItemPopupContent(item);
    } else if (item.hasOwnProperty("parentId")) {
      // Implement edit functionality
      setFolderPopupStatus(true);
      setItemPopupContent(item);
    }
  };

  function findPlaceholders(text) {
    const regex = /\{([^}]+)\}/g;
    let placeholders = [];
    let match;

    while ((match = regex.exec(text)) !== null) {
      placeholders.push(match[1]);
    }

    return placeholders;
  }

  const handleItemClick = (item) => {
    console.log("handleclick");
    if (!item.placeholders || item.placeholders.length === 0) {
      item.placeholders = findPlaceholders(item.prompt);
    }
    if (item.placeholders && item.placeholders.length > 0 && item.prompt) {
      MySwal.fire({
        html: (
          <PromptPopup
            prompt={item.prompt}
            note={item.note}
            placeholders={item.placeholders}
          />
        ),
        showConfirmButton: false,
        width: 800,
        padding: "12px",
      });
    } else {
      handleCopyPrompt(item.prompt);
    }
    // } else {
    //   navigate(`/collections/${item.id}`);
    // }
  };

  const renderItems = (items, type) => {
    console.log(items, "testingjoe");
    return items.map((item, index) => (
      <Draggable key={item.id} draggableId={item.id} index={index}>
        {(provided) => (
          <Item
            searchTerm={searchTerm}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            provided={provided}
            item={item}
            type={type}
            onEdit={handleEdit}
            // onDelete={handleDelete}
            onClick={handleItemClick}
          />
        )}
      </Draggable>
    ));
  };

  if (loading || fetchingAdditionalPrompts) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="main-container">
      <TabNavigation />
      <span className="collection-heading">
        <span className="back" onClick={() => navigate(-1)}>
          <BackButton />
        </span>
        <span className="collection-info">
          <h2 className="main-title">{currentCollection?.name}</h2>
          <p className="main-notes">{currentCollection?.notes}</p>
          {origin === "master" && (
            <p className="main-notes">{`Collections: ${filteredCollections.length} Prompts: ${filteredPrompts.length}`}</p>
          )}
        </span>
      </span>

      <SearchBar setSearchTerm={setSearchTerm} />
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="collections">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {renderItems(filteredCollections, "collection")}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Droppable droppableId="prompts">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {currentCollection && filteredPrompts.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  {!fetchingAdditionalPrompts && (
                    <Alert
                      severity="info"
                      sx={{
                        width: "80%",
                        margin: "auto",
                      }}
                    >
                      No prompts found
                    </Alert>
                  )}
                </Box>
              ) : (
                renderItems(filteredPrompts, "prompt")
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {user?.role === "admin" && (
        <>
          {" "}
          <AddPrompt />
          <AddFolder />
        </>
      )}
      {promptPopupStatus && (
        <EditPrompt
          openStatus={promptPopupStatus}
          promptPopupContent={itemPopupContent}
          handleClose={() => setPromptPopupStatus(false)}
        />
      )}
      {folderPopupStatus && (
        <EditFolder
          openStatus={folderPopupStatus}
          promptPopupContent={itemPopupContent}
          handleClose={() => setFolderPopupStatus(false)}
        />
      )}
    </div>
  );
};

export default Main;
